$white: #fff;
$semi_white: #f1f1f1;
$black: #222222;
$black_2: #151935;
$pure_black: #000;
$semi_black: #2b344e;
$hover: #ff5202;
$hover_2: #14bdee;
$hover_3: #002959;
$hover_4: #91c73d;
$hover_5: #f99e1c;
$hover_6: #032f62;
$hover_7: #2f345b;
$hover_8: #72748f;
$hover_9: #11142c;
$hover_10: #f5ffe6;
$deep_hover: #f34d01;
$semi_hover: #ffc6ac;
$semi_hover_2: #c8f3ff;
$semi_hover_3: #d0f2fc;
$semi_hover_4: #fff9f6;
$semi_hover_5: #eff7e2;
$dark: #ddd;
$semi_dark: #f7f7f7;
$semi_white: #a2a2a2;
$semi_white_2: #efefef;
$semi_white_3: #dddddd;
$semi_white_4: #f9f9ff;
$semi_white_5: #e8e8e8;
$dark_2: #002147;
$dark_3: #313448;
$dark_4: #a1a1a1;
$dark_5: #666;
$dark_6: #56566a;
$dark_7: #adadad;
$dark_8: #989898;
$dark_9: #f5f5f5;
$dark_10: #8a8d91;
$dark_11: #032d5c;
$dark_12: #dee2e6;
$dark_13: #1d2144;
$dark_14: #1e2458;
$secondary_color: #7551ec;
$default_color: #555;
$default_bg_color: #0d1028;
$fb_link_bg: #3b5999;
$twitter_link_bg: #55acee;
$pinterest_linbk_bg: #bd081c;
$default_size: 16px;
$default_font: 'Poppins', sans-serif;
$btn_padding: 10px 25px;
$bold_weight: 600;