/*--------------------------------------------------------------------------
Template name: Educa
Template URI: https://educa-html.netlify.com/
Author Name: Khakon
Author URI: 
Tag: Modern, Agency, Business
Verson: 1.0.0
Description: Educa is a responsive html5 multipurpose template 

===========================
CSS Index
===========================
1. header
2. Start Slider
3. Start Services
4. Start Featured Section
05. Start Popup Videos Section
06. Start Our Team Carousel
07 .Start Events Section
08. Start Testimonials Section
09. Start Blog Section
10. Start Footer
14. scroll-top
15. About Page
15.1 Start Featured-2 Section
15.2 Start Full Width Container
15.3 Start Contact And Countdown
15.4 Start Contact Section
16. Courses Page
17. Teacher Details
18. Gallery Page
19. Blog Style One
20. Apply Form Page
21. Notice page
22. Events Page
23. Results Page
24. Department Page
25. Blog Details
------------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,500i,600|Rubik:400,500,700&display=swap');
@import "partials/variables";
@import "partials/default";
@import "partials/media_query";
@import "partials/prefix";
@import "partials/navigation";
/*****************************
   1. header
*********************************/
body {
   font-family: 'Rubik', sans-serif;
   color: $dark_5;
   font-size: 16px;
}
body {
   p {
      line-height: 28px;
   }
}
.header-top {
   display: none;
   min-height: 45px;
   @include large {
      display: block;
   }
}
.header-top-content {
   display: flex;
   justify-content: space-between;
   .contact-info {
      ul {
         margin: 0;
         padding: 0;
         list-style: none;
         li {
            display: inline-block;
            line-height: 45px;
            padding-right: 40px;
            color: $white;
            font-size: 14px;
            i {
               color: $hover_2;
               padding-right: 5px;
            }
            &:last-child {
               padding-right: 0;
            }
         }
      }
   }
}
.single_info {
   display: inline-flex;
   padding-left: 30px;
   justify-content: center;
   align-items: center;
   justify-content: center;
   align-items: end;
   @include mobile {
      padding-left: 10px;
   }
   .info_data {
      display: inline-block;
      h6 {
         font-weight: 500;
         color: $dark_2;
      }
      p {
         margin-bottom: 0;
         color: $dark_5;
         @include tablet {
            font-size: 14px;
         }
         @include mobile {
            font-size: 14px;
         }
      }
   }
   img {
      padding-right: 20px;
      @include mobile {
         padding-right: 10px;
      }
   }
   .button_icon {
      position: absolute;
      right: 0;
      top: 50%;
    width: 60px;
    height: 100%;
    background-color: rgba($black,.09);
    text-align: center;
    transform: translateY(-50%);
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   }
}
.navbar-toggler {
  &:focus {
     outline-color: $hover;
  }
}
.main-menu-area {
   &.bg_dark_mobile {
      @include tablet {
         background-color: $default_bg_color;
      }
      @include mobile {
         background-color: $default_bg_color;
      }
   }

}
.header_top_area {
   @include desktop {
      .site_logo {
         display: none;
      }
   }
   @include tablet {
      .site_logo {
         display: none;
      }
   }
   .site_info {
      .single_info {
         &:first-child {
            padding-left: 0;
         }
      }
      @include desktop {
         width: 100%;
      }
      @include tablet {
         width: 100%;
      }
   }
   .button_icon {
      @include mobile {
         display: none;
      }
   }
   
   @include mobile {
       display: none;
    }
   
}
.main-menu {
 
   ul {
      li {
         a {
            color: $white;
            padding: 1rem 2rem !important;
            transition: all .4s linear 0s;
            &:hover {
               background-color: $hover;
            }
            @include large {
               padding: 1rem 1.3rem !important;
            }
         }
      }
   }
   .dropdown-menu {
      background-color: $default_bg_color;
   }
   .navbar {
      padding: 0;
   }
   .navbar-brand {
      i {
         color: $white;
      }
   }
   .dropdown-menu {
      transition: all .4s linear 0s;
      margin: 0;
      padding: 0;
      border-radius: 0;
      border: 0;
      .dropdown-item {
         &:hover {
            color: $white;
         }
      }
   }

   .dropdown-toggle {
      &::after {
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        border-top: 0;
        vertical-align: baseline;
      }
  }

  .nav-item.dropdown.show {
   background-color: $hover;
   color: $white;
}
@include large {
.dropdown {
   transition: all .4s linear 0s;
   &:hover {
       >.dropdown-menu {
           display: block;
         }
       background-color: $hover;
   }
   
}
}
.collapse-bar {
   position: relative;
}
.option-menu {
   position: absolute;
   right: 0;
   z-index: 5;
   margin-top: 8px;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      background-color: $default_bg_color;
   }
}
.navbar-brand {
   padding-right: .2rem;
}
.navbar {
   i {
      color: $white;
   }
}
@include desktop {
   padding: 15px 20px;
   .navbar-brand {
      display: none;
   }
}
@include tablet {
   padding: 15px 0;
   .navbar-brand {
      display: none;
   }
}

}

.main-menu-2 {
 
   ul {
      li {
         &:last-child {
            a {
               padding-right: 0 !important;
            }
         }
         a {
            color: $dark_5;
            padding: 1rem !important;
            transition: all .4s linear 0s;
            font-weight: 500;
            &:hover {
               color: $hover_2;
            }
         }
      }
   }

   .navbar {
      padding: 0;
   }
   .navbar-brand {
      i {
         color: $white;
      }
   }
   .dropdown-menu {
      transition: all .4s linear 0s;
      margin: 0;
      padding: 0;
      border-radius: 0;
   }

   .dropdown-toggle {
      &::after {
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        border-top: 0;
        vertical-align: baseline;
      }
  }

  .nav-item.dropdown.show {
   background-color: $dark_2;
   a {
      color: $white;
   }
   .dropdown-item {
      background-color: $dark_2;
   }
}
@include large {
   .dropdown {
      transition: all .4s linear 0s;
      &:hover {
          >.dropdown-menu {
              display: block;
            }
      }
      
   }
   }

.navbar {
   i {
      color: $dark_5;
   }
}

}

.main-menu-3 {
 
   ul {
      li {
         a {
            color: $dark_8;
            padding: 1rem 1rem !important;
            transition: all .4s linear 0s;
            font-weight: 500;
            &:hover {
               color: $white;
               background-color: $hover_4;
            }
            &.active {
               background-color: $hover_4;
               color: $white;
            }
            @include xl_large {
               padding: 1rem 1.5rem !important;
            }
         }
      }
   }

   .navbar {
      padding: 0;
   }
   .navbar-brand {
      i {
         color: $white;
      }
   }
   .dropdown-menu {
      transition: all .4s linear 0s;
      margin: 0;
      padding: 0;
      border-radius: 0;
      border: 0;
   }

   .dropdown-toggle {
      &::after {
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        border-top: 0;
        vertical-align: baseline;
      }
  }

  .nav-item.dropdown.show {
   background-color: $hover_2;
   color: $dark_5;
}
@include large {
   .dropdown {
      transition: all .4s linear 0s;
      &:hover {
          >.dropdown-menu {
              display: block;
            }
      }
      
   }
   }

.navbar {
   i {
      color: $dark_5;
   }
}

}

.mobile_site_logo {
   @include desktop {
      display: inline-block !important;
   }
   @include tablet {
      display: inline-block !important;
   }
}
@include large {
   .dropdown-menu {
      .dropdown {
          .dropdown-menu {
              left: 100%;
              top: 0%;
              margin:0 20px;
              border-width: 0;
          }
      }
   }  
   
   .dropdown-menu {
      .dropdown {
            .dropdown-menu {
               margin:0;
               border-width: 1px;
            }
      }
   }
}

/****************************
2. Start Slider
*****************************/
.slider-section {
   position: relative;
   overflow: hidden;
}
.swiper-container-horizontal {
   >.swiper-pagination-bullets {
      bottom: 10px;
      width: auto;
      top: 50%;
      left: 90%;
      transform: translateY(-50%);
      .swiper-pagination-bullet {
         margin: 20px 0;
         display: block;
         position: relative;
         width: 20px;
         height: 20px;
         background-color: $white;
         opacity: 1;
         &:before {
            content: "";
            position: absolute;
            left: -5px;
            top: -5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid $white;
         }
         &.swiper-pagination-bullet-active {
            background-color: $hover;
            &:before {
               content: "";
               position: absolute;
               left: -5px;
               top: -5px;
               width: 30px;
               height: 30px;
               border-radius: 50%;
               border: 2px solid $hover;
            }

         }
      } 
   }
}


.slider-area {
   position: relative;
   display: flex;
   align-items: center;
   background-size: cover;
   background-position: center center;
   width: 100%;
}
.slider-area-2 {
   position: relative;
   display: flex;
   align-items: center;
   background-size: cover;
   background-position: right top;
   width: 100%;
   .slider-content {
      position: relative;
      z-index: 9;
      h6 {
         position: relative;
         color: $hover_2;
         text-transform: uppercase;
         font-size: 20px;
      }
      h2 {
         position: relative;
         color: $dark_2;
         font-weight: 700;
         span {
            display: block;
            font-weight: 400;
         }
      }
      
   }
   .slider-content-2 {
      position: relative;
      z-index: 9;
      h6 {
         position: relative;
         color: $hover_2;
         text-transform: uppercase;
         font-size: 20px;
      }
      h2 {
         position: relative;
         color: $dark_2;
         font-family: 'Poppins', sans-serif;	
         font-weight:600;	
         font-size: 55px;
         span {
            display: block;
            font-weight: 400;
         }
      }
      p {
         position: relative;
      }
      @include desktop {
         .display-3 {
            font-size : 3rem;
         }
      }
      @include tablet {
         .display-3 {
            font-size : 3rem;
         }
      }
      @include mobile {
         .display-3 {
            font-size : 2rem;
         }
      }
      
   }
}
.slider-area-3 {
   position: relative;
   display: flex;
   align-items: center;
   background-size: cover;
   background-position: right top;
   width: 100%;
   &:before {
      content:"";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black,.6);
      z-index: 9;
   }
   .slider-content {
      position: relative;
      h6 {
         color: $white;
         text-transform: uppercase;
         font-size: 20px;
         font-weight: 400;
      }
      h2 {
         color: $white;
         span {
            display: block;
            font-weight: 400;
         }
      }
      

   }
   .slider-content-2 {
      position: relative;
      h6 {
         color: $hover_2;
         text-transform: uppercase;
         font-size: 20px;
      }
      h2 {
         color: $dark_2;
         span {
            display: block;
            font-weight: 400;
         }
      }
      p {
         font-family: 'Poppins', sans-serif;	
      }
      @include desktop {
         .display-3 {
            font-size : 3rem;
         }
      }
      @include tablet {
         .display-3 {
            font-size : 3rem;
         }
      }
      @include mobile {
         .display-3 {
            font-size : 2rem;
         }
      }
      
   }

}


.swiper-button-next {
   right: 30px;
   background-color: $hover_4;
   color: $white;
   padding: 25px;
   border-radius: 50%;
   background-size: 20px 30px;
   transition: all .4s linear 0s;
   background-image: none;
   &:before {
      content:"\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      color: $white;
      font-size: 25px;
      line-height: 25px;
      top: 50%;
      transform: translateY(-50%);
   }
   &:focus {
      outline: 0;
      box-shadow: none;
   }
   @include desktop {
      display: none;
   }
   @include tablet {
      display: none;
   }
   @include mobile {
      display: none;
   }
   &:hover {
      background-color: $dark_7;
   }
}

.swiper-button-prev {
   right: 30px;
   background-color: $hover_4;
   color: $white;
   padding: 25px;
   border-radius: 50%;
   background-size: 20px 30px;
   transition: all .4s linear 0s;
   background-image: none;
      &:before {
      content:"\f104";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      color: $white;
      font-size: 25px;
      line-height: 25px;
      top: 50%;
      transform: translateY(-50%);
   }
   &:focus {
      outline: 0;
      box-shadow: none;
   }
   @include desktop {
      display: none;
   }
   @include tablet {
      display: none;
   }
   @include mobile {
      display: none;
   }
   &:hover {
      background-color: $dark_7;
   }
}
.kindergarten{
   .swiper-button-prev{
      background:#D7D8DA;
      padding:24px;
      &:hover {
         background:#15BDEE;
      }
   }
}
.kindergarten{
   .swiper-button-next{
      background:#D7D8DA;
      padding:24px;
      &:hover {
         background:#15BDEE;
      }
   }

} 
.swiper-pagination {
   @include mobile {
      display: none !important;
   }
}
.slider-content {
   position: relative;
   z-index: 10;
   @include mobile {
     padding: 0!important;
     margin: 0 !important;
   }
   p {
      margin-bottom: 0;
      font-size: 20px;
   }
   h2 {
      font-weight: 500;
      font-family: "Poppins";
   }
   @include desktop {
      .display-3 {
         font-size : 3rem;
      }
   }
   @include tablet {
      .display-3 {
         font-size : 3rem;
      }
   }
   @include mobile {
      .display-3 {
         font-size : 2rem;
      }
   }
}
.slider-area.height-700 {
   @include mobile {
      height: 450px;
   }
}

/************************************
3. Start Services
**********************************/ 
.services-section {
   position: relative;
   z-index: 5;
}
.single-service {
   .service-icon {
      margin-bottom: 2rem;
   }
   a {
      color: $white;
      font-weight: 500;
      transition: all .4s linear 0s;
      i {
         transition: all .4s linear 0s;
      }
      &:hover {
         text-decoration: none;
         i {
            padding-left: 5px;
         }
      }
   }
   transition: all .4s linear 0s;
   &:hover {
      background-color: $hover;
   }
}
.service-text {
   h4 {
      margin-bottom: 1.2rem;
      font-weight: 600;
   }
}
.single-service-2 {
   h4 {
      color: $dark_2;
      font-weight: 600;
   }
   .service-icon {
      margin-bottom: 25px;
   }
   a {
      color: $hover_2;
      transition: all .4s linear 0s;
      i {
         transition: all .4s linear 0s;
      }
      &:hover {
        text-decoration: none;
        i {
           padding-left: 5px;
        }
      }
   }
}
.single-service-3 {
   transition: all .4s linear 0s;
   &.active {
      background-color: $hover;
      h4 {
         color: $white;
      }
      a {
         color: $white;
         text-transform:uppercase;
         font-weight:500;
      }
      &:hover {
         background-color: $hover;
         color: $white;
         a {
            color: $white;
         }
         h4 {
            color: $white;
         }
         .service-icon {
            padding: 15px;
            display: inline-block;
            background-color: $hover;
         }
      }
   }
   .service-icon {
      padding: 15px;
   }
   h4 {
      color: $dark_2;
   }
   .service-icon {
      margin-bottom: 25px;
   }
   a {
      color: $hover;
      transition: all .4s linear 0s;
      i {
         transition: all .4s linear 0s;
      }
      &:hover {
        text-decoration: none;
        i {
           padding-left: 5px;
        }
      }
   }
   &:hover {
      background-color: $hover;
      color: $white;
      a {
         color: $white;
      }
      h4 {
         color: $white;
      }
      .service-icon {
         display: inline-block;
         background-color: $white;
         border-radius: 50%;
         
      }
   }
}
.single-service-4 {
   transition: all .4s linear 0s;
   &.active {
      background-color: $hover;
      h4 {
         color: $white;
      }
      a {
         color: $white;
      }
   }
   h4 {
      color: $dark_2;
   }
   .service-icon {
      margin-bottom: 25px;
   }
   a {
      color: $hover;
      transition: all .4s linear 0s;
      i {
         transition: all .4s linear 0s;
      }
   }
   &:hover {
      box-shadow: 0 1rem 3rem rgba($hover_2,.35)!important;
      border-color: $hover_2;
   }
}
/*********************************
4. Start Featured Section
**********************************/ 
.featured-section-2 {
  background-size: 100% 53%;
  background-repeat: no-repeat;
  @include tablet {
     padding-bottom: 250px;
  }
}
.feature-image {
   position: relative;
   img {
      width: 100%;
   }
}
.single-featured {
   transition: all .4s linear 0s;
   box-shadow: none;
   &:hover {
      border-color: $hover !important;
   }

   .product-price {
      position: absolute;
      right: 30px;
      bottom: 30px;
   }
}
.single-featured-2 {
   transition: all .4s linear 0s;
   box-shadow: none;
   &:hover {
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
      border-color: $hover_2 !important;
      .featured-text-and-info {
         border-color: $hover_2 !important;
      }
   }
   .feature-image {
      position: relative;
      img {
         width: 100%;
      }
   }
   .product-price-2 {
      position: absolute;
      right: 30px;
      bottom: 30px;
   }

   .event-date-2 {
      position: absolute;
      right: 30px;
      bottom: 30px;
   }
}
.single-featured-3 {
   transition: all .4s linear 0s;
   &:hover {
     .border-6 {
      border-color: $hover_2;
     }
   }
   h3 {
      padding-top: 30px;
      font-weight: 600;
   }
   .featured-text-and-info {
      border-top-color: $hover_2;
      transition: all .4s linear .09s;
   }
}
.single-featured-4 {
   transition: all .4s linear 0s;
   &:hover {
     .border-6 {
      border-color: $hover_2;
     }
   }
   h3 {
      padding-top: 30px;
      font-weight: 600;
   }
   .featured-text-and-info {
      border-top-color: $hover_2;
      transition: all .4s linear 0s;
   }
   .feature-image {
      position: relative;
      .product-price-4 {
         position: absolute;
         right: 30px;
         bottom: 30px;
      }
      @include tablet {
         img {
            width: 100%;
         }
      }
   }
   &:hover {
      box-shadow: 0 1rem 3rem rgba($hover_4,.3);
   }
}
.single-featured-5 {
   transition: all .4s linear 0s;
   &:hover {
     .border-6 {
      border-color: $hover;
     }
   }
   h3 {
      padding-top: 30px;
      font-weight: 600;
   }
   .featured-text-and-info {
      border-top-color: $hover;
      transition: all .4s linear .09s;
   }
   .feature-image {
      position: relative;
      .product-price-4 {
         position: absolute;
         right: 30px;
         bottom: 30px;
      }
   }
}
.featured-text-and-info {
   position: relative;
}
.feature-image {
   position: relative;
}

.feature-status {
   display: flex;
   justify-content: space-between;
   span {
      color: $semi_white;
   }
   i {
      color: $hover;
      font-size: 14px;
   }
}
.feature-status-2 {
   display: flex;
   justify-content: space-between;
   span {
      color: $semi_white;
   }
   i {
      color: $hover_2;
      font-size: 14px;
   }
}
.feature-status-3 {
   display: flex;
   justify-content: space-between;
   span {
      color: $semi_white;
      i {
         color: $hover_5;
      }
   }
   i {
      color: $semi_white;
      font-size: 14px;
   }
}
.feature-status-4 {
   display: flex;
   justify-content: space-between;
   span {
      color: $semi_white;
      i {
         color: $hover_4;
      }
   }
   i {
      color: $hover_4;
      font-size: 14px;
   }
}
.feature-content {
    h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 15px;
       a {
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            text-decoration: none;
            color: $hover;
         }
       }
    }
}
.feature-content-2 {
    h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 15px;
       a {
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            text-decoration: none;
            color: $hover_2;
         }
       }
    }
}
.feature-content-3 {
    h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      padding-top: 0;
      padding-bottom: 15px;
       a {
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            text-decoration: none;
            color: $hover_4;
         }
       }
    }
}
.product-price {
   display: inline-block;
   padding: .6rem 1rem;
   background-color: $hover;
   color: $white;
   font-size: 22px;
   font-weight: 500;
   text-align: center;
   p {
      margin-bottom: 0;
   }
}
.product-price-2 {
   display: inline-block;
   padding: .6rem 1rem;
   background-color: $hover_2;
   color: $white;
   font-size: 22px;
   font-weight: 500;
   text-align: center;
   p {
      margin-bottom: 0;
   }
}
.product-price-3 {
   display: inline-block;
   width: 70px;
   height: 70px;
   border-radius: 50%;
   border: 2px solid $hover_2;
   font-size: 24px;
   font-weight: 500;
   line-height: 70px;
   color: $hover_2;
   background-color: $white;
   z-index: 2;
   p {
      margin-bottom: 0;
      line-height: 68px;
   }
   position: absolute;
   bottom: -35px;
   left: 50%;
   transform: translateX(-50%);
}
.product-price-4 {
   display: inline-block;
   padding: .6rem 1rem;
   background-color: $hover_4;
   color: $white;
   font-size: 22px;
   font-weight: 500;
   text-align: center;
   p {
      margin-bottom: 0;
   }
}
.product-price-5 {
   display: inline-block;
   width: 70px;
   height: 70px;
   border-radius: 50%;
   border: 2px solid $hover;
   font-size: 24px;
   font-weight: 500;
   line-height: 70px;
   color: $hover;
   background-color: $white;
   z-index: 2;
   p {
      margin-bottom: 0;
      line-height: 68px;
   }
   position: absolute;
   bottom: -35px;
   left: 50%;
   transform: translateX(-50%);
}

.event-date-2 {
   display: inline-block;
   padding: .6rem 1rem;
   background-color: $hover_2;
   color: $white;
   font-size: 20px;
   text-align: center;
   p {
      margin-bottom: 0;
   }
}
.courses-list {
   text-align: center;
   @include tablet {
      text-align: left;
   }
   @include mobile {
      text-align: left;
   }
   ul {
      li {
         cursor: pointer;
         margin: 5px;
      }
   }
}
/********************************
05. Start Popup Videos Section
**********************************/ 
.countdown-wrapper {
   @include tablet{
      &.translate_50 {
         transform: translateY(15%) !important;
      }
   }
   @include mobile{
      &.translate_50 {
         transform: translateY(5%) !important;
      }
   }
}
.single-countdown {
   text-align: center;
   p {
      color: $white;
      font-weight: 600;
   }
   span {
      color: $hover;
      font-size: 60px;
      font-weight: 600;
   }
   border-width: 6px;
   border-style: solid;
   border-image: url(assets/images/border.png) 5;
   border-image-outset: inherit;
   border-image-repeat: no-repeat;
   border-image-repeat: repeat;
   @include desktop {
      border-width: 5px;
   }
}
.single-countdown-2 {
   text-align: center;
   p {
      color: $dark_5;
      font-weight: 600;
      font-size: 14px;
   }
   span {
      color: $hover_2;
      font-size: 60px;
      font-weight: 600;
   }
   border-width: 7px;
   border-style: solid;
   border-image: url(assets/images/border.png) 7;
   border-image-outset: inherit;
   border-image-repeat: no-repeat;
   border-image-repeat: repeat;
   @include desktop {
      border-width: 6px;
   }
}
.single-countdown-3 {
   text-align: center;
   p {
      color: $white;
      font-weight: 600;
   }
   span {
      color: $hover_2;
      font-size: 60px;
      font-weight: 600;
   }
   border-width: 6px;
   border-style: solid;
   border-image: url(assets/images/border.png) 5;
   border-image-outset: inherit;
   border-image-repeat: no-repeat;
   border-image-repeat: repeat;
   background-color: $dark_2;
   @include desktop {
      border-width: 5px;
   }
}
.single-countdown-4 {
   text-align: center;
   p {
      color: $white;
      font-weight: 600;
      font-size: 20px;
   }
   span {
      color: $hover_4;
      font-size: 60px;
      font-weight: 600;
   }

    border-width: 6px;
    border-style: solid;
    border-image: url(assets/images/border.png) 5;
    border-image-outset: inherit;
    border-image-repeat: no-repeat;
    border-image-repeat: repeat;
    background-color: $default_bg_color;
    @include desktop {
      p {
         font-size: 15px;
      }
   }
}
.single-countdown-5 {
   text-align: center;
   background-color: $dark_2;
   p {
      color: $white;
      font-weight: 600;
      font-size: 20px;
   }
   span {
      color: $hover_2;
      font-size: 60px;
      font-weight: 600;
   }

    border-width: 6px;
    border-style: solid;
    border-color: $white;
    @include desktop {
      p {
         font-size: 15px;
      }
   }
}
.half_column_image {
   position: relative;
   transition: all .4s linear 0s;
   .video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
    i {
       font-size: 55px;
       color: $hover;
       background-color: $white;
       border-radius: 50%;
       padding: 10px;
       transition: all .4s linear 0s;
    }
   }
   &:hover {
      i {
         background-color: $hover;
         color: $white;
      }
   }
   @include desktop {
      img {
         width: 100%;
      }
   }
   @include large {
      img {
         object-fit: cover;
      }
   }
   .play-button-2 {
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 15px 25px;
      color: $white;
      background-color: $hover_4;
      transition: all .4s linear 0s;
      font-weight: 500;
      &:hover {
         text-decoration: none;
         background-color: rgba($hover_4,.8);
      }
   }
}
.half_column_image_2 {
   img {
      object-fit: cover;
   }
}
.half_column_content_2 {
   h2 {
      font-size: 45px;
      color: $dark_2;
      padding-bottom: 15px;
      font-weight: 600;
      text-transform: capitalize;
   }
   @include tablet {
      h2 {
       font-size: 30px;
     }
   }
   @include mobile {
      h2 {
         font-size: 30px;
      }
      
   }
}
.post-meta-info {
   display: inline-block;
   border: 2px solid $hover;
   width: 50%;
   padding: 1.5rem;
   background-color: $semi_hover_4;
   margin-top:5px;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         color: $hover;
         padding: 5px;
      }
   }
}
.two-column-section {
   &.section-pt {
      @include tablet {
         padding: 50px 0;
      }
      @include mobile {
         padding: 50px 0;
      }
   }
}
/*********************************
06. Start Our Team Carousel
********************************/ 
.our-team-carousel-4 {
   margin-bottom: -20px;
}
.member-info {
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: $white;
   padding: 20px;
   font-family: 'Poppins', sans-serif;
   transition: all .4s linear 0s;
   p {
      margin-bottom: 0;
      font-size: 14px;
   }
   a {
      color: $white;
      font-size: 14px;
      &:hover {
         text-decoration: none;
      }
   }
   h6 {
      font-size: 16px;
      font-weight: 500;
   }
   span {
      font-size: 14px;
   }
}
.member-info-2 {
   .extra-skilled {
      p {
         margin-bottom: 0;
         padding: 5px 0;
         strong {
            color: $dark_2;
            font-size: 14px;
         }
      }
   }
   span {
      color: $dark_5;
      font-size: 14px;
   }
   h5 {
      font-size: 18px;
      color: $dark_2;
   }
}
.member-info-3 {
   h5 {
      color: $dark_2;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;	
      font-weight:600;
   
   }
   span {
      color: $dark_5;
      font-size: 14px;
      font-weight:500;
   }
}
.member-info-4 {
   h5 {
      color: $dark_2;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;	
      font-weight:600;	
   }
   span {
      color: $dark_5;
      font-size: 14px;
   }
}

.social-profile {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         a {
            display: inline-block;
            width: 35px;
            height: 35px;
            line-height: 35px;
            color: $hover;
            border: 1px solid $hover;
            border-radius: 50%;
            background-color: $white;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 5px 0;
            &:hover {
               background-color: $hover;
               color: $white;
            }
         }
      }
   }
}
.member-image {
   position: relative;
   overflow: hidden;
   .social-profile {
      position: absolute;
      top: 30px;
      right: 30px;
      opacity: 0;
      visibility: hidden;
      transition: all .4s linear 0s;
   }
   img {
      width: 100%;
   }
}
.member-image-2 {
   position: relative;
   overflow: hidden;
}
.member-image-3 {
   padding-bottom: 20px;
   overflow: hidden;
 img {
   width: 200px !important;
   height: 200px;
   margin: 0 auto;
   border-radius: 50%;
 }
}
.social-profile-2 {
   position: absolute;
   bottom: 15px;
   text-align: center;
   opacity: 0;
   visibility: hidden;
   transition: all .4s linear 0s;
   left: 0;
   right: 0;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         margin: 0 5px;
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $hover_2;
            border-radius: 50%;
            background-color: $white;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 5px 0;
            &:hover {
               background-color: $hover_2;
               color: $white;
            }
         }
      }
   }
}
.social-profile-3 {
   position: absolute;
   bottom: 15px;
   text-align: center;
   opacity: 0;
   visibility: hidden;
   transition: all .4s linear 0s;
   left: 0;
   right: 0;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         margin: 0 5px;
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $dark_4;
            border-radius: 50%;
            background-color: $semi_white_2;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 5px 0;
            &:hover {
               background-color: $hover;
               color: $white;
            }
         }
      }
   }
}
.social-profile-4 {
   position: absolute;
   bottom: 15px;
   text-align: center;
   opacity: 0;
   visibility: hidden;
   transition: all .4s linear 0s;
   left: 0;
   right: 0;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         margin: 0 5px;
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $dark_4;
            border-radius: 50%;
            background-color: $semi_white_2;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 5px 0;
            &:hover {
               background-color: $hover_2;
               color: $white;
            }
         }
      }
   }
}
.social-profile-5 {
   position: relative;
   text-align: center;
   transition: all .4s linear 0s;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         margin: 0 5px;
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $dark_4;
            border-radius: 50%;
            background-color: $semi_white_2;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 5px 0;
            &:hover {
               background-color: $hover_4;
               color: $white;
            }
         }
      }
   }
}
.social-profile-6 {
   position: absolute;
   bottom: 0;
   text-align: center;
   transition: all .4s linear 0s;
   left: 0;
   right: 0;
   background-color: $dark_2;
   padding: 10px 0;
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         margin: 0 5px;
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $white;
            border-radius: 50%;
            background-color: $dark_11;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 5px 0;
            &:hover {
               background-color: $hover;
               color: $white;
            }
         }
      }
   }
}

.single-team {
   overflow: hidden;
   &:hover {
      .social-profile {
         opacity: 1;
         visibility: visible;
      }
      .member-info {
         background-color: $hover;
      }
     .social-profile-2 {
        opacity: 1;
        visibility: visible;
     }
     .social-profile-3 {
        opacity: 1;
        visibility: visible;
     }
     .social-profile-4 {
        opacity: 1;
        visibility: visible;
     }
     .member-info-4 {
        background-color: $hover;
        color: $white;
        transition: all .4s linear 0s;
        .designation {
           h5 {
              color: $white;
           }
           span {
            color: $white;
           }
        }
     }
     .member-image-2 {
        overflow: hidden;
        img {
           transform: scale3d(1.2,1.2,1.3);
        }
     }
   }
   img {
      width: 100%;
      transition: all .4s linear 0s;
   }
}
.border-opt {
 .single-team {
    transition: all .4s linear 0s;
    &:hover {
        .border {
           border-color: $hover_2 !important;
           border-width: 2px !important;
        }
    }
 }  
}
.show_navigation {
   position: relative;
   .owl-nav{
      position: absolute;
      top: -120px;
      right: 0;
      @include desktop {
        display: none;
      }
      @include tablet {
        display: none;
      }
      button {
         margin: 10px;
         i {
            font-size: 22px;
         }
         &.owl-prev {
            width: 50px;
            height: 50px;
            background: $semi_white_2;
            line-height: 55px;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            &:hover {
               background-color: $hover;
               border-color: $hover;
               color: $white;
            }
         }
         &.owl-next {
            width: 50px;
            height: 50px;
            line-height: 55px;
            background:$semi_white_2;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            &:hover {
               background-color: $hover;
               border-color: $hover;
               color: $white;
            }
         }
         &:focus {
            outline: 0;
            box-shadow: none;
         }
      }
   }
}
.show_navigation_2 {
   position: relative;
   .owl-nav{
      position: absolute;
      top: -120px;
      right: 0;
      @include desktop {
        display: none;
      }
      @include tablet {
        display: none;
      }
      button {
         margin: 10px;
         i {
            font-size: 22px;
         }
         &.owl-prev {
            width: 50px;
            height: 50px;
            background: $semi_white_2;
            line-height: 55px;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            &:hover {
               background-color: $hover_2;
               border-color: $hover_2;
               color: $white;
            }
         }
         &.owl-next {
            width: 50px;
            height: 50px;
            line-height: 55px;
            background:$semi_white_2;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            &:hover {
               background-color: $hover_2;
               border-color: $hover_2;
               color: $white;
            }
         }
         &:focus {
            outline: 0;
            box-shadow: none;
         }
      }
   }
}
.show_navigation_3 {
   position: relative;
   .owl-nav{
      font-size: 30px;
      @include desktop {
        display: none;
      }
      @include tablet {
        display: none;
      }
      button {
         margin: 10px;
         i {
            font-size: 22px;
         }
         &.owl-prev {
            position: absolute;
            width: 50px;
            height: 50px;
            background: $semi_white_2;
            line-height: 50px;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            @include xl_large {
               display: inline-block;
               left: -80px;
            }
            &:hover {
               background-color: $hover;
               border-color: $hover;
               color: $white;
            }
         }
         &.owl-next {
            position: absolute;
            width: 50px;
            height: 50px;
            line-height: 50px;
            background:$semi_white_2;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            transform: translateY(-50%);
            top: 50%;
            display: none;
            @include xl_large {
               display: inline-block;
               right: -80px;
            }
            &:hover {
               background-color: $hover;
               border-color: $hover;
               color: $white;
            }
         }
         &:focus {
            outline: 0;
            box-shadow: none;
         }
      }
   }
}
.show_navigation_4 {
   position: relative;
   .owl-nav{
      font-size: 30px;
      @include desktop {
        display: none;
      }
      @include tablet {
        display: none;
      }
      button {
         margin: 10px;
         i {
            font-size: 22px;
         }
         &.owl-prev {
            position: absolute;
            width: 50px;
            height: 50px;
            background: $semi_white_2;
            line-height: 50px;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            left: -90px;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
               background-color: $hover_4;
               border-color: $hover_4;
               color: $white;
            }
            @include large {
               display: none;
            }
            @include xl_large {
               display: block;
            }
         }
         &.owl-next {
            position: absolute;
            width: 50px;
            height: 50px;
            line-height: 50px;
            background:$semi_white_2;
            display: inline-block;
            border-radius: 50%;
            color: $dark_4;
            border: 1px solid $dark_4;
            transition: all .4s linear 0s;
            right: -90px;
            transform: translateY(-50%);
            top: 50%;
            &:hover {
               background-color: $hover_4;
               border-color: $hover_4;
               color: $white;
            }
            @include large {
               display: none;
            }
            @include xl_large {
               display: block;
            }
         }
         &:focus {
            outline: 0;
            box-shadow: none;
         }
      }

   }
}

/**********************
07 .Start Events Section 
*************************************/ 
.single-event {
   display: flex;
   justify-content: space-between;
   .event-date {
      display: inline-block;
      padding: 5px 35px;
      background-color: $white;
      text-align: center;
      margin-right: 30px;
      border: 4px solid $dark;
      min-width: 160px;
      height: 160px;
      
      span {
         font-size: 65px;
         color: $hover;
         font-weight: 600;
      }
      p {
         font-size: 24px;
         color: $dark_6;
         font-weight: 500;
         margin-bottom: 0;
      }
   }
   h5 {
      font-size: 20px;
      font-weight: 600;
      a {
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            color: $hover;
            text-decoration: none;
         }
      }
   }
   .event-meta {
      display: flex;
      .single-meta {
         padding-left: 40px;
         &:first-child {
            padding-left: 0;
         }
         p {
            @include xl_large {
               font-size: 16px !important;
            }
            @include large {
               font-size: 12px;
            }
            @include mobile {
               font-size: 12px;
            }
         }
      }
   }
   i {
      color: $hover;
   }
   
}
.event-content {
   h2 {
      font-size: 24px;
      margin-bottom: 15px;
      font-family: "Poppins";
      a {
       color: $dark_2;
       transition: all .4s linear 0s;
       &:hover {
          text-decoration: none;
          color: $hover;
       }
      }
   }
}
.event-content-one {
   h2 {
      font-size: 24px;
      margin-bottom: 15px;
      font-family: "Poppins";
      font-weight: 600;
      a {
       color: $dark_2;
       transition: all .4s linear 0s;
       &:hover {
          text-decoration: none;
          color: $hover;
       }
      }
   }
}
.single-event-2 {
   display: flex;
   justify-content: space-between;
   .event-date {
      display: inline-block;
      padding: 5px 35px;
      background-color: $white;
      text-align: center;
      margin-right: 30px;
      border: 3px solid $dark;
      min-width: 160px;

      span {
         font-size: 65px;
         color: $hover_2;
         font-weight: 600;
      }
      p {
         font-size: 24px;
         color: $dark_6;
         margin-bottom: 0;
         font-weight: 600;
      }
   }
   h5 {
      font-size: 20px;
      font-weight: 500;
      a {
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            color: $hover_2;
            text-decoration: none;
         }
      }
   }
   .event-meta {
      display: flex;
      .single-meta {
         padding-left: 40px;
         @include large {
            padding-left: 12px;
         }
         &:first-child {
            padding-left: 0;
         }
         p {
            @include mobile {
               font-size: 12px;
            }
            @include large {
               font-size: 14px;
            }
         }
      }
   }
   i {
      color: $hover_2;
   }
   
}
.single-event-3 {
   display: flex;
   justify-content: space-between;
   .event-date {
      display: inline-block;
      padding: 5px 35px;
      background-color: $white;
      text-align: center;
      margin-right: 30px;
      border: 3px solid $dark;
      min-width: 160px;
      span {
         font-size: 65px;
         color: $hover_4;
         font-weight: 600;
      }
      p {
         font-size: 24px;
         color: $dark_6;
         margin-bottom: 0;
      }
   }
   h5 {
      font-size: 20px;
      font-weight: 500;
      a {
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            color: $hover_4;
            text-decoration: none;
         }
      }
   }
   .event-meta {
      display: flex;
      .single-meta {
         padding-left: 40px;
         @include large {
            padding-left: 12px;
         }
         &:first-child {
            padding-left: 0;
         }
         p {
            @include mobile {
               font-size: 12px;
            }
            @include large {
               font-size: 14px;
            }
         }
      }
   }
   i {
      color: $hover_4;
   }
   
}
.event-content {
   h2 {
      font-size: 24px;
      margin-bottom: 15px;
      a {
       color: $dark_2;
       transition: all .4s linear 0s;
       &:hover {
          text-decoration: none;
          color: $hover_2;
       }
      }
   }
}

.events-info {
   display: flex;
   position: absolute;
   bottom: 30px;
   justify-content: space-between;
   padding: 15px 20px;
   width: 85%;
   left: 7%;
   border-radius: 30px;
   font-size: 14px;
   color: $dark_6;
   @include mobile {
      width: 90%;
      left: 5%;
   }
   i {
      color: $hover;
      font-size: 15px;
   }
   p {
      margin-bottom: 0;
      @include mobile {
         font-size: 12px;
      }
   }
}
.events-info-2 {
   display: flex;
   justify-content: center;
   position: absolute;
   bottom: 30px;
   padding: 15px 20px;
   width: 80%;
   left: 10%;
   border-radius: 30px;
   font-size: 14px;
   color: $dark_6;
   @include tablet {
      width: 96%;
      left: 2%;
   }
   @include mobile {
      width: 96%;
      left: 2%;
   }
   i {
      color: $hover;
      font-size: 15px;
   }
   p {
      margin-bottom: 0;
   }
   .single-event-data {
      padding: 0 5px;
      a {
         color: $hover;
         &:hover {
            text-decoration: none;
         }
      }
      span {
         color: $hover;
         font-weight: 500;
      }
   }
}
.single-blog {
   .post-meta {
      position: absolute;
      bottom: 30px;
      padding: 15px 20px;
      width: 70%;
      left: 15%;
      border-radius: 30px;
      font-size: 14px;
      color: $dark_6;
      text-align: center;
      @include mobile {
         width: 90%;
         left: 5%;
      }
      i {
         color: $hover;
         font-size: 15px;
      }
      p {
         margin-bottom: 0;
      }
      .single-event-data {
         display: inline-block;
         a {
            color: $hover;
         }
         p {
            padding: 0 5px;
            font-size: 14px;
            color: $dark_5;
         }
      }
   }
}

.event-image {
   position: relative;
   overflow: hidden;
  @include desktop {
     img {
        width: 100%;
     }
  }
}
/*****************************
08. Start Testimonials Section
********************************/ 
.single-testimonial {
   position: relative;
   &:before {
      content:"\f10d";
      position: absolute;
      left: 30px;
      top: -30px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      line-height: 50px;
      background-color: $hover;
      color: $white;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      text-align: center;
   }
   margin-top: 30px; 
   padding-top: 2.3em !important;
   p {
      line-height: 28px;
     
     
   }
}
.testimonial-referance {
   p {
      margin-bottom: 0;
   }
}
.single-testimonial-2 {
   position: relative;
   &:before {
      content: "\f10d";
      position: absolute;
      left: 125px;
      top: -85px;
      z-index: 9;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      line-height: 40px;
      background-color: $hover_2;
      color: $white;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 14px;
      text-align: center;
      border: 3px solid $white;
   }
   margin-top: 80px; 
   .testimonial-pic {
      position: absolute;
      top: -55px;
      left: 40px;
      img {
         width: 110px;
         height: 110px;
      }
   }
   .testimonial-text {
      p {
         font-style: italic;
         font-size: 18px;
      }
   }
   .testimonial-referance {
      strong {
         color: $dark_2;
         font-size: 16px;
      }
      p {
         font-size: 16px;
         margin-bottom: 0;
      }
   }
   p {
      line-height: 28px;
   }
}
.single-testimonial-3 {
   position: relative;
   &:before {
      content:"\f10d";
      position: absolute;
      left: 50%;
      top: -40px;
      transform: translateX(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      line-height: 50px;
      background-color: $hover;
      color: $white;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      text-align: center;
   }
   margin-top: 60px;
   padding-top: 30px;
   p {
      font-style: italic;
      font-size: 16px;
   }
   .testimonial-referance {
      strong {
         display: block;
      }
   }
   p {
      line-height: 28px;
   }
}
.single-testimonial-4 {
   position: relative;
   &:before {
      content:"\f10d";
      position: absolute;
      left: 50%;
      top: -40px;
      transform: translateX(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      line-height: 50px;
      background-color: $hover_2;
      color: $white;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      text-align: center;
   }
   margin-top: 60px;
   padding-top: 30px;
   p {
      font-style: italic;
      font-size: 16px;
   }
   .testimonial-referance {
      strong {
         display: block;
      }
   }
   p {
      line-height: 28px;
   }
}
.single-testimonial-6 {
   position: relative;
   text-align: center;
   margin-bottom: 45px;
   >p {
      display: inline-block;
      font-style: italic;
      font-size: 16px;
      &:after {
         content:"\f10e";
         font-family: "Font Awesome 5 Free";
         font-weight: 900;
         position: absolute;
         padding-left: 10px;
         font-size: 22px;
         color: $dark_14;
      }
   }
   .testimonial-referance {
      p {
         margin-bottom: 0;
      }
   }
.quote-author-pic {
   position: absolute;
   left: 50%;
   bottom: -45px;
   background-repeat: no-repeat;
   background-size: cover;
   width: 90px;
   height: 90px;
   transform: translateX(-50%);
   img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
   }
}
p {
   line-height: 28px;
}
}
.testimonials-wrapper {

   .owl-dots {
       text-align: center;
       margin-top: 60px;
       line-height: 10px;
       button {
          &:focus {
             outline: 0;
             box-shadow: none;
          }
       }
      .owl-dot {
         &.active {
            span {
               background-color: $hover;
               height: 30px;
            }
         }
         span {
            width: 10px;
            height: 20px;
            margin: 5px 7px 0;
            background: $semi_hover;
            display: inline-block;
            -webkit-backface-visibility: visible;
            transition: opacity .2s ease;
            border-radius: 30px;
            transition: all .5s linear .2s;
        }
      }
   } 
}
.testimonials-wrapper-2 {

   .owl-dots {
       text-align: center;
       margin-top: 60px;
       button {
          &:focus {
             outline: 0;
             box-shadow: none;
          }
       }
      .owl-dot {
         &.active {
            transition: all .4s linear .09s;
            span {
              
               background-color: $hover_2;
               height: 30px;
            }
         }
         span {
            width: 10px;
            height: 20px;
            margin: 5px 7px;
            background: $semi_hover_2;
            display: inline-block;
            -webkit-backface-visibility: visible;
            transition: opacity .2s ease;
            border-radius: 30px;
            transition: all .4s linear 0s;
        }
      }
   } 
}
.testimonials-wrapper-3 {

   .owl-dots {
       text-align: center;
       margin-top: 30px;
       line-height: 10px;
       button {
          &:focus {
             outline: 0;
             box-shadow: none;
          }
       }
      .owl-dot {
         &.active {
            span {
               background-color: $hover;
               height: 30px;
            }
         }
         span {
            width: 10px;
            height: 20px;
            margin: 0 5px 0 7px;
            background: $dark_2;
            display: inline-block;
            -webkit-backface-visibility: visible;
            transition: opacity .2s ease;
            border-radius: 30px;
            transition: all .4s linear 0s;
        }
      }
   } 
}
.testimonials-wrapper-4 {

   .owl-dots {
       text-align: center;
       margin-top: 30px;
       button {
          &:focus {
             outline: 0;
             box-shadow: none;
          }
       }
      .owl-dot {
         &.active {
            span {
               background-color: $hover_2;
               height: 30px;
            }
         }
         span {
            width: 10px;
            height: 20px;
            margin: 5px 7px;
            background: $white;
            display: inline-block;
            -webkit-backface-visibility: visible;
            transition: opacity .2s ease;
            border-radius: 30px;
            transition: all .4s linear 0s;
        }
      }
   } 
}
.has_bordered {
   position: relative;
   h2 {
      display: inline-block;
      position: relative;
      &:before {
         content:"";
         position: absolute;
         left: 50%;
         bottom: -5px;
         width: 50%;
         height: 1px;
         background-color: $hover_2;
         transform: translateX(-50%);
      }
   }
}
/*************************
09. Start Blog Section
****************************/

.blog-lists-wrapper {
   .single-blog-wrapper {
      display: flex;
      justify-content: space-between;
      .blog-thumb {
         img {
            width: 140px;
            height: 140px;
            object-fit: cover;
         }
      }
      margin-bottom: 30px;
      &:last-child {
         margin-bottom: 0;
      }
   }
   .blog-content-wrapper {
      position: relative;
      h4 {
         font-size: 16px;
         font-weight: 600;
         font-family: 'Poppins', sans-serif;
         line-height: 1.5rem;
         a {
            color: $dark_2;
            transition: all .4s linear 0s;
            font-weight: 600;
            font-family: "Poppins";
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
      }
      p {
         margin-bottom: 5px;
      }
   }
}

.blog-lists-wrapper-2 {
   .single-blog-wrapper {
      display: flex;
      justify-content: space-between;
      .blog-thumb {
         img {
            width: 140px;
            height: 140px;
            object-fit: cover;
         }
      }
      margin-bottom: 30px;
      &:last-child {
         margin-bottom: 0;
      }
   }
   .blog-content-wrapper {
      h4 {
         font-size: 16px;
         font-weight: 600;
         font-family: 'Poppins', sans-serif;
         line-height: 1.5rem;
         a {
            color: $dark_2;
            transition: all .4s linear 0s;
            font-family: "Poppins";
            &:hover {
               text-decoration: none;
               color: $hover_2;
            }
         }
      }
      p {
         margin-bottom: 5px;
      }
   }
   .post-meta {
      position: relative !important;
   }
}
.blog-lists-wrapper-3 {
   .single-blog-wrapper {
      display: flex;
      justify-content: space-between;
      .blog-thumb {
         img {
            width: 140px;
            height: 140px;
            object-fit: cover;
         }
      }
      margin-bottom: 30px;
      &:last-child {
         margin-bottom: 0;
      }
   }
   .blog-content-wrapper {
      h4 {
         font-size: 16px;
         font-weight: 500;
         font-family: 'Poppins', sans-serif;
         line-height: 1.5rem;
         a {
            color: $dark_2;
            transition: all .4s linear 0s;
            font-family: "Poppins";
            &:hover {
               text-decoration: none;
               color: $hover_4;
            }
         }
      }
      p {
         margin-bottom: 5px;
      }
   }
}

.post-meta {
   span {
      font-size: 14px;
      font-style: italic;
   }
}
.big-blog-area {
   .blog-meta {
      span {
         color: $hover;
         font-size: 14px;
         font-weight: 500;
         &.seperator {
            font-size: 12px;
            color: $dark_2;
         }
         a {
            color: $hover;
         }
      }
   }
   .blog-meta-2 {
      span {
         color: $hover_2;
         font-size: 14px;
         a {
            color: $hover_2;
         }
      }
   }
   .blog-text {
      h3 {
         margin-bottom: 0;
         padding: 15px 0;
         text-transform: capitalize;
         font-weight: 600;
         a {
            color: $dark_2;
            transition: all .4s linear 0s;
            margin-top: 0;
            text-transform: capitalize;
            font-size: 20px;
            line-height: 1.8rem;
            font-weight: 600;
            font-family: "Poppins";
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
      }
      a {
         display: inline-block;
         color: $hover;
         font-weight: 500;
         font-size: 15px;
         text-transform: uppercase;
         margin-top: 15px;
         transition: all .4s linear 0s;
         i {
            transition: all .4s linear 0s;
         }
         &:hover {
            text-decoration: none;
            i {
               padding-left: 5px;
            }
         }
      }
   }
   .blog-text-2 {
      h3 {
         margin-bottom: 0;
         padding: 15px 0;
         text-transform: capitalize;

         a {
            color: $dark_2;
            transition: all .4s linear 0s;
            margin-top: 0;
            text-transform: capitalize;
            font-size: 20px;
            line-height: 1.8rem;
            font-family: "Poppins";
            font-weight: 600;
            &:hover {
               text-decoration: none;
               color: $hover_2;
            }
         }
      }
      a {
         display: inline-block;
         color: $hover_2;
         font-weight: 500;
         font-size: 15px;
         text-transform: uppercase;
         margin-top: 15px;
         transition: all .4s linear 0s;
         i {
            transition: all .4s linear 0s;
         }
         &:hover {
            text-decoration: none;
            i {
               padding-left: 5px;
            }
         }
      }
   }
   .blog-thumb {
      img {
         @include desktop {
            width: 100%;
         }
         @include tablet {
            width: 100%;
         }
         @include mobile {
            width: 100%;
         }
      }
   }
}
.single_blog {
   .blog-content {
      transition: all .4s linear 0s;
   }
   .event-image {
      img {
         transition: all .4s linear 0s;
      }
   }
   &:hover {
      .blog-content {
         box-shadow: 0 .5rem 1rem rgba($black,.15) !important;
      }
      .event-image {
         img {
            transform: scale3d(1.1,1.1,1.1);
         }
      }
      
   }
}
.subscribe_box {
   display: flex;
   position: relative;
   height: 100%;
   align-items: center;
   justify-content: center;
   text-align: center;
   color: $white;
   min-height: 400px;
   .subscribe-text {
      i {
         font-size: 100px;
         margin-bottom: 40px;
      }
      h3 {
         font-size: 50px;
        
      }
      p {
         margin-bottom: 0;
         font-size: 18px;
         text-transform: capitalize;

      }
      margin-bottom: 80px;
   }
   .subscribe-form {
      position: absolute;
      width: 100%;
      bottom:0;
      left:0;
      button {
         position: absolute;
         right: 0;
         top: 0;
         height: 100%;
         width: 80px;
         border: 0;
         background-color: $hover_2;
         color: $white;
         i {
            font-size: 35px;
         }
         &:focus {
            outline: 0;
         }
      }
      input {
         border-radius: 0;
         height: 80px;
         background-color: $hover_3;
         color: $white;
         border: 0;
         padding-left: 25px;
         &:focus {
            outline: 0;
            box-shadow: none;
         }
      }
   }

}
/********************
10. Start Footer
**********************/
.footer-bottom-wrapper {
   border-color: rgba($white,.3) !important;
}
.footer-area {
   color: $white;
   .footer-logo {
      padding-bottom: 30px;
      @include mobile {
         padding-top: 30px;
         padding-bottom: 20px;
      }
   }
}
.widget-item {
   display: flex;
   margin-bottom: 30px;
   .widget-image {
      margin-right: 20px;
      img {
         width: 70px;
         height: 70px;
         object-fit: cover;
      }
   }
   .widget-content {
      h5 {

         a {
            color: $white;
            font-size: 16px;
            font-weight: 400;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
      }
   }
   &:last-child {
      margin-bottom: 0;
   }
   .widget-content-2 {
      h5 {

         a {
            color: $white;
            font-size: 16px;
            font-weight: 400;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover_2;
            }
         }
      }
   }
   .widget-content-3 {
      h5 {
         a {
            color: $white;
            font-size: 16px;
            font-weight: 400;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover_4;
            }
         }
      }
   }
   &:last-child {
      margin-bottom: 0;
   }
}
.widget-meta {
   span {
      font-size: 14px;
      font-style: italic;
   }
}
.widget-list {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         &:first-child {
            a {
               padding-top: 0;
            }
         }
         a {
            display: inline-block;
            font-size: 16px;
            color: $white;
            padding: 5px;
            text-transform: capitalize;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
      }
   }
}
.widget-list-2 {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         &:first-child {
            a {
               padding-top: 0;
            }
         }
         a {
            display: inline-block;
            font-size: 16px;
            color: $white;
            padding: 5px;
            text-transform: capitalize;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover_2;
            }
         }
      }
   }
}
.widget-list-3 {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         &:first-child {
            a {
               padding-top: 0;
            }
         }
         a {
            display: inline-block;
            font-size: 16px;
            color: $white;
            padding: 5px;
            text-transform: capitalize;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover_4;
            }
         }
      }
   }
}
.social-accounts {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         &:last-child {
            a {
               margin-right: 0;
            }
         }
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $white;
            border: 1px solid $semi_black;
            border-radius: 50%;
            background-color: $semi_black;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 0 5px;
            @include mobile {
               width: 35px;
               height: 35px;
               line-height: 35px;
               margin: 0 3px;
            }
            &:hover {
               background-color: $hover;
               color: $white;
               border-color: $hover;
            }
         }
      }
   }
}
.social-accounts-2 {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         &:last-child {
            a {
               margin-right: 0;
            }
         }
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $white;
            border: 1px solid $semi_black;
            border-radius: 50%;
            background-color: $semi_black;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 0 5px;
            &:hover {
               background-color: $hover_2;
               color: $white;
               border-color: $hover_2;
            }
         }
      }
   }
}
.social-accounts-3 {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         &:last-child {
            a {
               margin-right: 0;
            }
         }
         a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $white;
            border: 1px solid $semi_black;
            border-radius: 50%;
            background-color: $semi_black;
            transition: all .4s linear 0s;
            text-align: center;
            margin: 0 5px;
            &:hover {
               background-color: $hover_4;
               color: $white;
               border-color: $hover_4;
            }
         }
      }
   }
}
.footer-bottom {
   display: flex;
   justify-content: space-between;
   align-items: center;
   p {
      margin-bottom: 0;
   }
}
.address-widget {
   p {
      line-height: 2rem;
   }
}
.single_widget {
   h3 {
      margin-bottom: 0;
      padding-bottom: 30px;
      font-weight: 500;
   }
}
.address-widget {
   span {
      font-size: 28px;
   }
}
.subscribe-wrapper {
   position: relative;
   border-top: 10px solid rgba($semi_hover,.3);
   transform: translateY(50%);
   h2 {
      font-family: 'Poppins', sans-serif;
   }
   input {
      font-style:italic;
   }
   @include tablet {
      transform: translateY(49%);
   }
   @include mobile {
      transform: translateY(49%);
   }
   .subscribe-form {
      form {
         position: relative;
      }
   }
   h2 {
      font-size: 50px;
      color: $dark_2;
      @include mobile {
         font-size: 30px;
      }
   }
   input {
      border: 2px solid $hover;
      border-radius: 0;
      height: 60px;
      border-radius: 35px;
      position: relative;
      padding: 0 15px;
      font-size: 14px;
      &:focus {
         outline: 0;
         box-shadow: none;
         border-color: $hover;
      }
   }
   button[type="submit"] {
      background-color: $hover;
      color: $white;
      text-transform: uppercase;
      font-weight: 500;
      position: absolute;
      height: 100%;
      border-radius: 35px;
      right: 0;
      top: 0;
      border: 0;
      width: 165px;
      font-size: 16px;
      &:focus {
         outline: 0;
         box-shadow: none;
      }
      @include desktop {
         border-radius: 20px;
         width: 110px;
         font-size: 14px;
      }
   }
   &:before {
      content:"";
      position: absolute;
      left:-10px;
      top: -10px;
      height: 53%;
      width: 10px;
      background-color: rgba($semi_hover,.3);
   }
   &:after {
      content:"";
      position: absolute;
      right: -10px;
      top: -10px;
      height: 53%;
      width: 10px;
      background-color: rgba($semi_hover,.3);
   }
}
/***********************
14. scroll-top
************************/

.scroll-top {
   position: fixed;
   width: 50px;
   height: 50px;
   background-color: $black;
   bottom: 80px;
   right: 20px;
   z-index: 9999;
   cursor: pointer;
   transition: all .4s linear 0s;
   display: none;
   border-radius: 50%;
}
.scroll-top {
   .scroll-icon {
       color: $white;
       font-size: 20px;
       line-height: 53px;
       text-align: center;
   }
}
.scroll-top {
   &:hover {
     background-color: $hover;
   }
   &.option-2 {
      background-color: $hover_2;
   }
   &.option-3 {
      background-color: $hover_4;
   }
   
}

/**********************************
15. About Page
***********************************/

.hero-section {
   display: flex;
   position: relative;
   align-items: center;
   justify-content: center;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   overflow: hidden;
   h1 {
      font-weight: 500;
   }
}

.image-gallery {
   .owl-item {
      .single-gallery {
         img {
            height: 250px;
            object-fit: cover;
         }
      }
      &.active {
         &.center {
            position: relative;
            z-index: 999;
            @include large {
               transform: scale(1.3);
            }
               .single-gallery {
                  img {
                      object-position: center center;
                    @include large {
                     border: 2px solid $hover;
                     margin: 12px 0;
                    }
                  }
               }
         }
         }

         position: relative;
         z-index: 1;
         margin-top: 25px;
   }
   .owl-dots {
      display: inline-block;
      position: absolute;
      text-align: center;
      margin-top: -50px;
      z-index: 9999;
      left: 44.5%;
      background-color: $hover;
      padding: 5px;
      border-radius: 50px;
      .owl-dot {
           width: 10px;
           height: 10px;
           background-color: rgba($black,.3);
           border-radius: 50%;
           margin: 5px;
           &.active {
              background-color: $white;
           }
           &:focus {
              outline: 0;
           }
         
      }
   }
}


/************************************
15.1 Start Featured-2 Section
****************************************/ 
.featured-area {
   margin-bottom: -20px;
}
.featured-2-icon {
     
   margin-right: 30px;
   i {
      color: $hover;
      font-size: 25px;
      width: 60px;
      height: 60px;
      line-height: 55px;
      text-align: center;
      border-radius: 50%;
      border: 2px solid $hover;
   }
}
.single-featured-3 {
   &:hover {
      .featured-2-icon {
         i {
            background:#ff5202;
            color:#fff;
            transition: all .4s linear 0s;
         }
      }
   }
}

.featured-2-text {
   h5 {
        color: $dark_2;
        font-size: 20px;
        font-family: 'Poppins', sans-serif;	
        font-weight:600;
   }
}

.half-column-content {
   h2 {
      font-size: 50px;
   }
}
/************************
15.2 Start Full Width Container
*************************/

.half-column-video {
   
   &:before {
      content:"";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color:rgba($black,.3);
   }
   .play-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: $hover_2;
      background-color: rgba($white,.6);
      padding: 15px;
      border-radius: 50%;
      transition: all .4s linear 0s;
      i {
         font-size: 30px;
         background-color: $white;
         padding: 35px;
         border-radius: 50%;
      }
      &:hover {
         background-color: $hover_2;
      }
   }
   @include tablet {
      padding: 200px 0;
   }
   @include mobile {
      padding: 200px 0;
   }
}
.contact-form-wrapper {
   h2 {
      font-size: 50px;
      margin-bottom: 30px;
   }
   input {
      margin-bottom: 30px;
      border-radius: 0;
      background-color: transparent;
      border:2px solid $hover_6;
      border-radius: 40px;
      height: 50px;
      padding: 25px;
      color: $semi_white_3;
      &:focus {
         background-color: transparent;
         color: $semi_white_3;
      }
   }
   textarea {
      border-radius: 0;
      background-color: transparent;
      margin-bottom: 30px;
      border-color: $hover_6;
      border-radius: 40px;
      padding: 25px;
      color: $semi_white_3;
      &:focus {
         background-color: transparent;
         color: $semi_white_3;
      }
   }
   ::-webkit-input-placeholder { 
      color: $semi_white_3;
    }
    ::-moz-placeholder { 
      color: $semi_white_3;
    }
    :-ms-input-placeholder { 
      color: $semi_white_3;
    }
    :-moz-placeholder { 
      color: $semi_white_3;
    }
}
.equal-height {
   background-size: cover;
}

/**************************
15.3 Start Contact And Countdown
***********************************/ 
.reg-form-area {
   transform: translateY(-25%);
   position: absolute;
   width: 90%;
   @include desktop {
      position: relative;
      margin-bottom: -25%;
   }
   @include tablet {
      position: relative;
      margin-bottom: -35%;
   }
   @include mobile {
      position: relative;
      margin-bottom: -30%;
   }
   h2 {
      margin-bottom: 30px;
      font-weight: 600;
      font-family: "Poppins";
   }
   input {
      border-radius: 0;
      margin-bottom: 30px;
      border: 1px solid $hover_7;
      background-color: $default_bg_color;
      color: $white;
      height: 55px;
      &:focus {
         background-color: $default_bg_color;
      }
   }
   ::-webkit-input-placeholder { 
      color: $white;
    }
    ::-moz-placeholder { 
      color: $white;
    }
    :-ms-input-placeholder { 
      color: $white;
    }
    :-moz-placeholder { 
      color: $white;
    }
    button[type="submit"]{
       border: 0;
       height: 55px;
       background-color: $hover_4;
       color: $white;
       font-size: 16px;
       text-transform: uppercase;
       font-weight: 500;
       letter-spacing: 2px;
    }
}
/**************************
15.4 Start Contact Section
****************************/ 
.contact-from-wrapper {
   input {
      border-radius: 0;
      height: 50px;
      background-color: $semi_white_4;
      position: relative;
      &:focus {
         background-color: $hover_10;
         border-color: $hover_4;
         box-shadow: none;
         label {
            color: $hover_4;
         }
      }
   }
   label {
      position: absolute;
      right: 40px;
      top: 25px;;
      transform: translateY(-50%);
      z-index: 3;
      color: $dark_10;
   }
   textarea {
      background-color: $semi_white_4;
      border-radius: 0;
      position: relative;
      &:focus {
         background-color: $hover_10;
         border-color: $hover_4;
         box-shadow: none;
      }
   }
   button[type="submit"] {
      padding: 15px 25px;
      background-color: $hover_4;
      color: $white;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      border: 0;
      border-radius: 50px;
      &:focus {
         outline: 0;
      }
   }
}
.contact-from-wrapper-2 {
   overflow: hidden;
   input {
      border-radius: 0;
      height: 50px;
      background-color: $semi_white_4;
      position: relative;
      &:focus {
         background-color: $semi_hover;
         border-color: $hover;
         box-shadow: none;
         color: $hover;
         font-weight: 500;
      }
   }

   textarea {
      background-color: $semi_white_4;
      border-radius: 0;
      position: relative;
      &:focus {
         background-color: $semi_hover;
         border-color: $hover;
         box-shadow: none;
         color: $hover;
         font-weight: 500;
      }
   }
   button[type="submit"] {
      padding: 15px 25px;
      background-color: $hover;
      color: $white;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      border: 0;
      &:focus {
         outline: 0;
      }
   }
}

/**********************
16. Courses Page
**************************/

.portfolios-list-2 {
   ul {
      li {
         margin: 5px;
      }
   }
}

/******************************
17. Teacher Details
********************************/ 
.single-timeline {
   h4 {
      color: $dark_2;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;	
      font-weight:600;	
   }
   span {
      color: $hover;
      font-size: 14px;
   }
   .timeline-text {
      padding-top: 25px;
   }
   p {
      margin-bottom: 0;
   }
}
.timeline-wrapper {
   position: relative;
   @include large {
      &:after {
         content: "\f105";
         font-family: "Font Awesome 5 Free";
         font-weight: 900;
         position: absolute;
         right: 0;
         color: $white;
         background-color: $hover;
         border-radius: 50%;
         text-align: center;
         top: -40px;
         width: 30px;
         height: 30px;
         font-size: 22px;
         line-height: 30px;
      }
      &:before {
         content:"";
         position: absolute;
         left: 0;
         background-color: $semi_white_5;
         top: -40px;
         width: 30px;
         height: 30px;
         font-size: 22px;
         line-height: 30px;
      }
      .single-timeline {
         position: relative;
         &:before {
            content: "\f192";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            top: -50px;
            left: 0;
            width: 50px;
            height: 50px;
            color: $white;
            background-color: $hover;
            border-radius: 50%;
            font-size: 30px;
            line-height: 50px;
            text-align: center;
         }
   
      }
   }
   
}

.page-section-title {
   h2 {
      font-size: 50px;
      color: $dark_2;
      font-family: 'Poppins', sans-serif;	
      font-weight:600;
   }
   padding-bottom: 60px;
}
.half_columnn_image {
   img {
      width: 100%;
   }
}
.half_column_content {
   h3 {
       font-size: 35px;
       color: $dark_2;
       font-weight: 600;
       font-family: 'Poppins', sans-serif;
   }
   h6 {
      font-size: 20px;
      color: $dark_5;
      font-weight: 600;
   }
   p {
      line-height: 28px;
   }
}
.secondary-content {
   display: inline-block;
   width: 50%;
   float: left;
   h6 {
      font-size: 16px;
      color: $dark_2;
   }
}
.single-member-info {
   p {
      margin-bottom: 0;
   }
   .member-intro {
      h3 {
         a {
            font-size: 20px;
            color: $dark_2;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
            }
         }
      }
      p {
         font-size: 14px;
         color: $dark_5;
         strong {
            color: $dark_2;
         }
      }
   }
   .member-intro {
      padding: 20px 0 30px;
   }
}
.member-status {
   display: flex;
   justify-content: space-between;
   p {
      font-size: 14px;
      color: $dark_5;
      strong {
         color: $dark_2;
         font-weight: 500;
      }
   }
}
.member-image {
   overflow: hidden;
   img {
      transition: all .4s linear 0s;
   }
}
.single-member {
   transition: all .4s linear 0s;
   &:hover {
      box-shadow: 0 .5rem 1rem rgba($black,.15) !important;
      .member-intro {
         h3 {
            a {
                color: $hover;
            }
         }
         p {
            color: $hover;
            strong {
               color: $hover;
            }
         }
      } 
      .member-status {
         p {
            strong {
               color: $hover;
            }
         }
      }
      .member-image {
         img {
             transform: scale3d(1.1,1.1,1.1);
         }
      }
      
   }
}
.search-form {
   select {
      border-radius: 0;
      margin-bottom: 20px;
      color: $dark_5;
      height: 50px;
      &:focus {
         outline: 0;
         box-shadow: none;
      }
   }
   input {
      border-radius: 0;
      margin-bottom: 20px;
      color: $dark_5;
      height: 50px;
      &:focus {
         outline: 0;
         box-shadow: none;
      }
   }
}
.button-widget {
   background-size: cover;
   background-position: center center;
   h3 {
   
      color: $dark_2;
      padding-bottom: 15px;
      font-size:24px;
      line-height:35px;
      font-weight:500;
   }
}
.widget-video-img {
   position: relative;
   text-align: center;
   &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($dark_2,.5);
   }
   a {
      display: inline-block;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 60px;
      color: $white;
      background-color: $hover;
      border-radius: 50%;
      line-height: 55px;
      transition: all .4s linear 0s;
      padding: 10px;
   }
   &:hover {
      a {
         background-color: $white;
         color: $hover;
      }
   }
   img {
      width: 100%;
   }
}
.highlights-section {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding: 10px 0;
      li {
         display: inline-block;
         padding: 5px;
      }
      h4 {
         line-height:35px;
      }
   }
   .social-links {
      ul {
         li {
            a {
               display: inline-block;
               width: 40px;
               height: 40px;
               line-height: 40px;
               color: $white;
               border: 2px solid  $white;
               border-radius: 50%;
               background-color: rgba($white,.19);
               transition: all .4s linear 0s;
               text-align: center;
               &:hover {
                  background-color: $white;
                  border-color: $white;
                  color: $hover;
               }
            }
         }
      }
   }
}
/*********************************
18. Gallery Page
***********************************/ 
.gallery-links {
   ul {
      margin: 0;
      padding: 0;
      li {
         display: inline-block;
         margin: 5px;
         a {
            display: inline-block;
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background-color: rgba($hover,.3);
            border-radius: 50%;
            border: 2px solid $white;
            color: $white;
            transition: all .4s linear 0s;
            &:hover {
               background-color: $white;
               color: $hover;
               border-color: $white;
            }
         }
      }
   }
   display: inline-block;
   padding-top: 15px;
   border-top: 2px solid $white;
}
.gallery-content {
   text-align: center;
   position: absolute;
   left: 50%;
   top: 50%;
   width: 100%;
   transform: translate(-50%,-50%);
   opacity: 0;
   visibility: hidden;
   transition: all .4s linear 0s;
   h3 {
      font-size: 30px;
      color: $white;
      margin-bottom: 20px;
      padding: 0 25px;
   }
}
.single-gallery {
   .gallery-image {
      position: relative;
      &:before {
         content:"";
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background-color: rgba($hover,.8);
         opacity: 0;
         visibility: hidden;
         transition: all .4s linear 0s;
      }
      &:hover {
         &:before {
           opacity: 1;
           visibility: visible;
         }
        }
        img {
           width: 100%;
        }
   }
   transition: all .4s linear 0s;
   &:hover {
      .gallery-content {
         opacity: 1;
         visibility: visible;
      }
   }
}

/****************************
19. Blog Style One
****************************/ 
.post-text {
   h2 {
      a {
         font-size: 30px;
         color: $dark_2;
         transition: all .4s linear 0s;
         &:hover {
            text-decoration: none;
            color: $hover;
         }
      }
   }
}
.search-widget {
   form {
      position: relative;
      input {
         border-radius: 0;
         height: 60px;
         background-color: $semi_dark;
         &.home2-opt {
            &:focus {
               border-color: $hover_2;
            }
         }
         &:focus {
            outline: 0;
            box-shadow: none;
            border-color: $hover;
         }
      }
      button[type="submit"] {
         position: absolute;
         top: 0;
         right: 0;
         background-color: $hover;
         color: $white;
         width: 80px;
         height: 100%;
         font-size: 25px;
         color: $white;
         border: 0;
         &.home2-opt {
            background-color: $hover_2;
         }
      }
   }
}
.latest-widget {
   .single-latest {
      display: flex;
      .latest-image {
         padding-right: 10px;
         a {
            display: inline-block
         }
         img {
            width: 120px;
            height: 80px;
            object-fit: cover;
         }
      }
      padding: 20px 0;
      border-bottom: 1px solid $dark;
      &:last-child {
         border-bottom: 0;
         padding-bottom: 0;
      }
      .latest-content {
         a {
            font-size: 16px;
            color: $dark_2;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
         .latest-meta {
            span {
               color: $dark_5;
               font-size: 14px;
            }
         }
      }
   }
   padding: 25px;
}
.category-list {
   ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
         a {
            display: flex;
            justify-content: space-between;
            color: $dark_5;
            padding: 10px 0;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
         &:last-child {
            padding-bottom: 0;
         }
      }
   }
}
.sidebar-wrapper {
   .single-widget {
      margin-bottom: 45px;
      &:last-child {
         margin-bottom: 0;
      }
      h4 {
         font-size: 20px;
         color: $dark_2;
         text-align: center;
         font-weight:600;
         font-family: 'Poppins', sans-serif;
      }
   }
}
.tag-clouds {
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         display: inline-block;
         a {
            display: inline-block;
            color: $dark_5;
            text-transform: uppercase;
            font-size: 15px;
            padding: 10px 15px;
            background-color: $dark_9;
            margin: 5px 3px;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $white;
               background-color: $hover;
            }
         }
      }
   }
}
/*****************************
20. Apply Form Page
*************************************/ 
.apply-wrapper {
   h2 {
      font-size: 50px;
      color: $dark_2;
      text-align: center;
      padding-bottom: 35px;
      font-family: 'Poppins', sans-serif;
      font-weight:600;
      @include mobile {
         font-size: 35px;
      }
   }
   input {
      border-radius: 0;
      height: 60px;
      &:focus {
         outline: 0;
         box-shadow: none;
         border-color: $hover;
      }
   }
   select {
      border-radius: 0;
      height: 60px;
      &:focus {
         outline: 0;
         box-shadow: none;
         border-color: $hover;
      }
   }
   textarea {
      border-radius: 0;
      &:focus {
         outline: 0;
         box-shadow: none;
         border-color: $hover;
      } 
   }
}
.form-group{
    label{
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	color: #002147;	
}
}
/*******************************
21. Notice page
*******************************/ 
.single-event-info {
   display: flex;
   justify-content: space-around;
   align-items: center;
   transition: all .4s linear 0s;
   border: 2px solid $dark;
   .single-event-date {
      width: 135px;
      height: 135px;
   }
   @include tablet {
      display: inline-block;
      text-align: center;
      .single-event-date {
         margin: 0 auto 20px auto;
      }
   }
   &:hover {
      box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
      border-color: $hover !important;
      .single-event-date {
         background-color: $hover;
         color: $white;
         span {
            color: $white
         }
         p {
            color: $white
         }
      }
     
   }
}
.single-event-content {
   h3 {
      a {
         font-size: 24px;
         color: $dark_2;
         transition: all .4s linear 0s;
         font-weight: 600;
         @include desktop {
            font-size: 20px;
         }
         &:hover {
            text-decoration: none;
            color: $hover;
         }
      }
   }
   p {
      color: $dark_5;
      margin-bottom: 5px;
      i {
         padding-right: 5px;
      }
   }
   @include large {
      padding: 0 25px;
   }
   @include desktop {
      padding-left: 20px;
   }
}
.single-event-date {
   p {
      margin-bottom: 0;
      font-size: 20px;
      color: $hover;
   }
   span {
      font-size: 50px;
      color: $hover;
      font-weight: 600;
   }
   text-align: center;
   border: 2px solid $hover;
   transition: all .4s linear 0s;
}
/*****************************
22. Events Page
******************************/ 

.single-blog {
   .blog-meta {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      p {
         margin-bottom: 0;
         color: $dark_5;
         font-size: 14px;
      }
      i {
         color: $hover;
      }
   }
   .blog-text {
      h4 {
         a {
            color: $dark_2;
            font-size: 20px;
            transition: all .4s linear 0s;
            &:hover {
               text-decoration: none;
               color: $hover;
            }
         }
      }
   }
}
.blog-image {
   a {
      img {
         width: 100%;
      }
   }
}
/************************
23. Results Page
**************************/ 
.search-form {
   select {
      background-color: $dark_9;
      &:focus {
         border-color: $hover;
      }
   }
   input {
      background-color: $dark_9;
      &:focus {
         border-color: $hover;
      }
   }
}
.results-area {
   h2 {
      font-size: 50px;
      color: $dark_2;
   }
   .table {
      margin-bottom: 0;
   }
}
/*************************
24. Department Page
************************/ 
.single-item {
   padding: 3rem 1.5rem;
   border: 2px solid $dark;
   text-align: center;
   transition: all .4s linear 0s;
   h4 {
      a {
         color: $dark_2;
         padding: 15px 0;
         transition: all .4s linear 0s;
         &:hover {
            text-decoration: none;
            color: $hover;
         }
      }
   }
   .item-icon {
      padding-bottom: 30px;
   }
   &:hover {
      box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
      border-color: $hover;
   }
}

/****************************
25. Blog Details
*****************************/ 

.details-meta {
   display: flex;
   padding-top:.8em;
   p {
      margin-bottom: 0;
   }
   .single-meta {
      padding-right: 50px;
      &:last-child {
         padding-right: 0;
      }
      i {
         color: $hover;
         padding-right: 5px;
      }
      p {
         font-size: 14px;
         color: $dark_5;
         @include mobile {
            font-size: 12px;
         }
      }

   }
}
.details-title {
   h2 {
      font-size: 30px;
      color: $dark_2;
   }
}
.tags-and-share {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 30px 0;
   border-top: 2px solid $dark_12;
   border-bottom: 2px solid $dark_12;
   margin: 60px 0;
}
.comments-count {
   h2 {
      font-size: 20px;
      color: $dark_2;
      font-family: 'Poppins', sans-serif;
      font-weight:600;
   }
}
.author-thumb {
   width: 260px;
   padding-right: 20px;
   a {
      display: inline-block;
   }
   img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
   }
}
.authors-info {
   display: flex;
}

.single-comment {
   position: relative;
   .reply-btn {
      position: absolute;
      top:0;
      right: 0;
      color: $hover;
      font-size: 16px;
      font-weight: 500;
      &:hover {
         text-decoration: none;
      }
   }
}
.comments-count {
  margin-bottom: 30px;
}
.author-data {
   a {
      color: $dark_2;
      font-size: 16px;
      font-weight: 600;
      transition: all .4s linear 0s;
      font-family: 'Poppins', sans-serif;
      &:hover {
         text-decoration: none;
         color: $hover;
      }
   }
   p {
      margin-bottom: 5px;
      margin-bottom: 8px;
      margin-top: 8px;
      font-family: 'Poppins', sans-serif;
   }
}
.comment-form-wrapper {
   h2 {
      font-size: 20px;
      color: $dark_2;
      margin-bottom: 35px;
   }
   input {
      margin-bottom: 30px;
      border-radius: 0;
      background-color: $dark_9;
      height: 50px;
      &:focus {
         outline: 0;
         box-shadow: none;
         border-color: $hover;
      }
   }
   textarea {
      border-radius: 0;
      background-color: $dark_9;
      outline: 0;
      box-shadow: none;
      margin-bottom: 30px;
      &:focus {
         outline: 0;
         box-shadow: none;
         border-color: $hover;
      }
   }
}
.single-blog-item {
   display: flex;
   border: 2px solid $dark_12;
   transition: all .4s linear 0s;
   &:hover {
      border-color: $hover;
   }
   .single-blog-thumb {
      padding-right: 15px;
      a {
         display: inline-block;
      }
       img{
         width: 140px;
         height: 75px;
         object-fit: cover;
       }
   }
   .single-blog-content {
      h4 {
         a {
            font-size: 16px;
            color: $dark_2;
            transition: all .4s linear 0s;
            &:hover {
               color: $hover;
               text-decoration: none;
            }
         }
      }
      span {
         color: $dark_5;
         font-size: 14px;
      }
   }
}
.latest-blogs-area {
  .owl-nav {
     text-align: center;
     margin-top: 35px;
     button {
        width: 45px;
        height: 45px;
        line-height: 45px;
        border-radius: 50%;
        background-color: $white !important;
        color: $hover;
        margin: 5px;
        border: 2px solid $dark_9 !important;
        transition: all .4s linear 0s;
        span {
           color: $hover;
           font-size: 25px;
        }
        &:hover {
         background-color: $hover !important;
         border-color: $hover !important;
         span {
            color: $white;
         }
      }
      &:focus {
         outline: 0;
         box-shadow: none;
      }
     }
  }
}
#search_modal {
   .modal-content {
      border-radius: 0;
   }
}
.details-text {
   p {
      line-height:30px;
   }
}
.timeline-area{
    p{
	font-weight:500;
}
}
/* Custom CSS */



.kindergarten {
   .button_two{
      font-family: 'Poppins', sans-serif;	
   }
}

.height-470{
	height:325px;
}


@media (max-width: 575px){
.slider-area.height-700{
   height: 300px;
}
.slider-area-2.height-600{
   height: 300px;
}

.slider-area-3.height-600 {
    height: 300px;
}

/* Homepage blog & event section */

.blog-lists-wrapper {
	.single-blog-wrapper {
		display: block;
		.blog-thumb {
			img {
				width: 100%;
				margin: 10px 0 15px;
			}
		}
	}
}
.single-event {
	display: block;
	.event-date {
		display: none;
	}
}
.single-event-3 {
	display: block;
	.event-date {
		display: none;
	}
}
.blog-lists-wrapper-2 {
	.single-blog-wrapper {
		display: block;
		.blog-thumb {
			img {
				width: 100%;
				margin: 10px 0 15px;
			}
		}
	}
}
.slider-area-2 {
	.slider-content-2 {
		
	}
}
.slider-area-3 {
	.slider-content {
		h6 {
			font-size: 15px;
		}
	}
}
.slider-content {
	.display-3 {
		font-size: 1.8rem;
	}
}


}